import React from "react";
import { Link } from "gatsby";
import { SEOComponent } from "../../components/SEO/SEOComponent";
import MainLayout from "../../components/Layouts/MainLayout";

const AvisoLegal = () => {
  return (
    <MainLayout>
      <section className="sm:w-s py-20">
        <h1 className="text-center sm:pb-4">Aviso legal</h1>
        <p className="text-center">
          Conoce los términos y condiciones y las política de privacidad de esta
          web.
        </p>
        <section className="sm:flex sm:justify-center py-5 sm:py-6">
          <div className="px-3">
            <Link to="/aviso-legal/terminos/">
              <button className="btn-gray w-full">
                Ver términos y condiciones
              </button>
            </Link>
          </div>
          <div className="px-3 mt-3 sm:mt-0">
            <Link to="/aviso-legal/privacidad/">
              <button className="btn-white w-full">
                Ver política de privacidad
              </button>
            </Link>
          </div>
        </section>
      </section>
    </MainLayout>
  );
};

export default AvisoLegal;

export const Head = () => (
  <SEOComponent
    title="Aviso legal"
    type="Page"
    featuredImage=""
    robots="noindex, follow"
    description="Aviso legal del sitio web www.zume.do"
    isCanonical={false}
    canonicalUrl={null}
    dateCreated={null}
    keywords=""
  />
);
